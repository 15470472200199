export default {
    name: "UnderlyingReports",
    created() {
        this.$nextTick(function () {
            this.getRicList()
        });
    },
    mounted() {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
            dataArr: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获得数据
        GetDeployedUnderlyingReport(ric) {
            let arr
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetDeployedUnderlyingReport",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == 'SUCCESS') {
                        this.dataArr = data.text
                        $.each(this.dataArr, (i, c) => {
                            arr = ric.filter((tiem) => {
                                return tiem.underlying_name == c.underlying_name
                            })
                            if (arr.length !== 0) {
                                c.ric = arr[0].underlying_ric
                            } else {
                                c.ric = ''
                            }
                        })
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        getRicList() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetRICList",
                async: false,
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        this.GetDeployedUnderlyingReport(data.ric)
                    }
                }
            })
        },
        // 跳转详细页面
        toDetailPage: function (url) {
            if (url) {
                window.open(url);
            }
        }
    }
};